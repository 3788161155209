import React from "react"

import cn from "classnames";

interface Tag {
  label: string;
  bgColor: string;
  textColor: string;
}

type Tags = string[] | Tag[]

interface Props {
  author: string
  published: string
  tags: Tags
}

export function BlogMeta(props: Props) {
  const { tags = [] } = props;

  function renderTagLabel(tag) {
    if (typeof tag === "string") {
      return tag
    }

    return tag?.label
  }

  function renderBackgroundColor(tag) {
    if (tag?.bgColor) {
      return tag?.bgColor;
    }

    return undefined;
  }

  function renderTextColor(tag) {
    if (tag?.textColor) {
      return tag?.textColor;
    }

    return undefined;
  }

  return (
    <div>
      <p className="text-gray-600 mt-2 mb-4" style={{ fontSize: "16px" }}>
        {props?.author} - {props?.published}
      </p>
      <div className="mb-4">
        {(tags as any)?.map((tag: Tag, index: number) => (
          <span key={index} className="inline-flex mr-2 items-center px-2.5 py-0.5 rounded-md text-sm font-medium leading-5 bg-teal-100 text-teal-800"
            style={{
              backgroundColor: renderBackgroundColor(tag),
              color: renderTextColor(tag)
            }}
            >
            {renderTagLabel(tag)}
          </span>
        ))}
      </div>
    </div>
  )
}
